.rich-text-content p,
.rich-text-content ul,
.rich-text-content ol,
.rich-text-content pre,
.rich-text-content blockquote {
    margin: 1rem 0;
}

.rich-text-content ul,
.rich-text-content ol {
    padding: 0 2.5rem;
}

.rich-text-content ul p,
.rich-text-content ol p,
.rich-text-content li ol,
.rich-text-content li ul
{
    margin: 0;
}

.rich-text-content pre {
    font-family: monospace;
    line-height: 1.25em;
}

.rich-text-content code {
    border-radius: 0.25rem;
    padding: 0.125em 0.25em;
}

.rich-text-content pre code  {
    border-radius: 0.25em;
    padding: 0.75em 1em;
}

.rich-text-content pre code, .rich-text-content code {
    font-size: 0.95em;
}

.rich-text-content blockquote {
    padding: 0.25em 1em;
    border-radius: 0.25em;
    border-left: 3px solid transparent;
}

.dashboard-light-theme .rich-text-content code,
.dashboard-light-theme .rich-text-content pre code,
.dashboard-light-theme .rich-text-content blockquote {
    color: #2f3337;
    background: #f6f6f6;
}

.dashboard-dark-theme .rich-text-content code,
.dashboard-dark-theme .rich-text-content pre code,
.dashboard-dark-theme .rich-text-content blockquote {
    color: #fff;
    background: #333D49;
}

.dashboard-light-theme .rich-text-content blockquote {
    border-left-color: #2f3337;
}

.dashboard-dark-theme .rich-text-content blockquote {
    border-left-color: #fff;
}


/* tree */

.person.male .container{
    stroke: #4d60af;
    fill: #d4e3fa;
}

.person.male .avatar{
    stroke: #8796d2;
    fill: #eaeaea;
}

.person.male .footer{
    fill: #5e76d6cc;
}

.person.male:hover .container{
    stroke: #5e76d6;
    fill: #dfe9fc;
}

.person.female .container{
    stroke: #c53535;
    fill: #fad4d4;
}

.person.female .avatar{
    stroke: #e87474;
    fill: #eaeaea;
}

.person.female .footer{
    fill: #ea5151cc;
}

.person.female:hover .container{
    stroke: #ea5151;
    fill: #ffe0e0;
}



.person.unknown .container{
    stroke: #8a8a8a;
    fill: #f1f1f1;
}

.person.unknown .avatar{
    stroke: #a6a6a6;
    fill: #f1f1f1;
}

.person.unknown .footer{
    fill: #a6a6a6;
}

.person.unknown:hover .container{
    stroke: #707070;
    fill: #ffffff;
}

.person.selected .container{
    stroke: #4f8d50;
    fill: #cde5cd;
}

.person.selected .avatar{
    stroke: #4f8d50;
    fill: #eaeaea;
}

.person.selected .footer{
    fill: #6aa86bcc;
}

.person.selected:hover .container{
    stroke: #6aa86b;
    fill: #e3f6e3;
}

.person .action{
    opacity: 0;
}

.person:hover .action{
    opacity: 1;
}